import React from 'react'

export default class EORI extends React.Component {

  constructor (props) {
    super(props)
    this.parent = props.parent
    this.state = {
      error: null,
      valid: ( this.parent.state.eori && this.parent.state.eori.valid ),
      loading: false, 
      number: ( this.parent.state.eori && this.parent.state.eori.number ? this.parent.state.eori.number : '' ),
      registeredOfficeAddress: ( this.parent.state.registeredOfficeAddress ? this.parent.start.registeredOfficeAddress : null ),
      name: ( this.parent.state.name ? this.parent.state.name : null )
    }
    this.update = this.update.bind(this)
    this.check = this.check.bind(this)
    this.inputClassName = this.inputClassName.bind(this)
    this.renderAppend = this.renderAppend.bind(this)
    this.isButtonDisabled = this.isButtonDisabled.bind(this)
  }

  componentDidUpdate () {
    if (this.parent.state.registeredOfficeAddress !== this.state.registeredOfficeAddress) this.setState({ registeredOfficeAddress: this.parent.state.registeredOfficeAddress })
    if (this.parent.state.name !== this.state.name) this.setState({ name: this.parent.state.name })
  }

  render () {
    return (
      <div className="form-group">
        <label>EORI Number</label>
        <div className="input-group">
          <input type="text" className={this.inputClassName()} onChange={this.update} onKeyPress={this.check} defaultValue={this.state.number} />
          { this.renderAppend() }
          { this.state.error ? <div className="invalid-feedback">{this.state.error}</div> : ''}
          { this.state.valid ? <div className="valid-feedback">EORI Number is valid</div> : ''}
        </div>
      </div>
    )
  }

  renderAppend () {
    if (this.state.valid) return null
    return (
      <div className="input-group-append">
        <button className="btn btn-outline-primary" type="button" disabled={this.isButtonDisabled()} onClick={this.check}>
          { this.state.loading ? <i className="fas fa-spinner fa-spin"></i> : 'Check' }
        </button>
      </div>
    )
  }

  isButtonDisabled () {
    if (!this.state.registeredOfficeAddress) return true
    if (!this.state.name) return true
    if (!this.state.number) return true
    if (this.state.error) return true
    if (this.state.loading) return true
    return false
  }

  inputClassName () {
    let className = 'form-control'
    if (this.state.error) return `${className} is-invalid`
    if (this.state.valid) return `${className} is-valid`
    return className
  }

  update (e) {
    e.target.value = e.target.value.replace(/\s/g, '').toUpperCase()
    this.setState({ valid: false })
    this.parent.setState({ eori: { valid: false } })
    if (e.target.value.length > 1 && !/GB.*|XI.*/g.test(e.target.value)) return this.setState({ error: 'EORI number must start with GB or XI' })
    return this.setState({ number: e.target.value, error: null })
  }

  async check (e) {
    if (e.type && e.type === 'keypress' && e.charCode !== 13) return
    this.setState({ loading: true })
    try {
      const body = {}
      if (this.state.registeredOfficeAddress) body.registeredOfficeAddress = this.state.registeredOfficeAddress
      if (this.state.name) body.name = this.state.name
      const raw = await fetch(`/application/eoricheck/${this.state.number}`, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: { 'Content-Type': 'application/json' }
      })
      const json = await raw.json()
      if (raw.status !== 200) throw json
      if (!json.valid && !json.reason) return this.setState({ error: 'The number you have entered is invalid', loading: false })
      if (!json.valid && json.reason) return this.setState({ error: json.reason, loading: false })
      this.parent.setState({ eori: { number: this.state.number, valid: true } })
      return this.setState({ valid: json.valid, loading: false })
    } catch (e) {
      return this.setState({ error: e.message, loading: false })
    }
  }

}