import React from 'react'

export default class Countries extends React.Component {

  constructor (props) {
    super(props)
    this.props = props
    this.parent = props.parent
    this.state = {
      countries: {},
      disabled: true,
      selectedCountries: ['']
    }
    if (this.parent.state.countries && this.parent.state.countries.length > 0) this.state.selectedCountries = this.parent.state.countries
    this.renderItem = this.renderItem.bind(this)
    this.renderCountries = this.renderCountries.bind(this)
    this.add = this.add.bind(this)
    this.updateCountries = this.updateCountries.bind(this)
    this.remove = this.remove.bind(this)
  }

  async componentDidMount () {
    const raw = await fetch('/application/countries')
    const json = await raw.json()
    this.setState({ countries: json, disabled: false })
  }

  render () {
    return this.state.selectedCountries.map((item, k) => this.renderItem(item, k))
  }

  renderItem (item, k) {
    return (
      <div className="input-group" key={k}>
        <select className={this.props.error ? 'form-control is-invalid' : 'form-control'} disabled={this.state.disabled} data-key={k} onChange={this.updateCountries} value={item}>{this.renderCountries()}</select>
        <div className="input-group-append">
          <button className="btn btn-outline-success" type="button" data-key={k} onClick={this.add} disabled={this.state.disabled}><i className="fas fa-plus"></i></button>
          {this.state.selectedCountries.length > 1 ? <button className="btn btn-outline-danger" type="button" data-key={k} onClick={this.remove}><i className="fas fa-trash"></i></button> : ''}
        </div>
        { this.props.error ? <div className="invalid-feedback">{this.props.error}</div> : '' }
      </div>
    )
  }

  renderCountries () {
    const countries = [<option value="" key="empty"></option>]
    Object.keys(this.state.countries).forEach(k => {
      countries.push(<option value={k} key={k}>{this.state.countries[k]}</option>)
    })
    return countries
  }

  add (e) {
    const countries = this.state.selectedCountries
    const key = parseInt(e.currentTarget.attributes['data-key'].value) + 1
    countries.splice(key, 0, '')
    this.setState({ selectedCountries: countries })
  }

  remove (e) {
    const countries = this.state.selectedCountries
    const key = parseInt(e.currentTarget.attributes['data-key'].value)
    countries.splice(key, 1)
    this.setState({ selectedCountries: countries })
    return this.parent.setState({ countries })
  }

  updateCountries (e) {
    const countries = this.state.selectedCountries
    const key = e.target.attributes['data-key'].value
    countries[key] = e.target.value
    this.setState({ selectedCountries: countries })
    return this.parent.setState({ countries })
  }

}