document.addEventListener('DOMContentLoaded', () => {

  if (!document.querySelector('#contact')) return

  onloadCallback = () => {
    grecaptcha.render('reCaptcha', {
      'sitekey' : '6LfHN8YaAAAAANB5zO7r9ebdqWoNYf2aEzjs9-tx'
    })
  }

})