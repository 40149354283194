import React from 'react'

import CompanyInformation from './CompanyInformation'
import ImportProfile from './ImportProfile'
import ContactInformation from './ContactInformation'
import Review from './Review'

export default class ApplicationForm extends React.Component {

  constructor (props) {
    super(props)
    this.props = props
    this.state = { page: 'company' }
    this.submit = this.submit.bind(this)
  }

  render () {
    if (this.state.page === 'company') return (<CompanyInformation parent={this}/>)
    if (this.state.page === 'import') return (<ImportProfile parent={this} />)
    if (this.state.page === 'contact') return (<ContactInformation parent={this} />)
    if (this.state.page === 'review') return (<Review parent={this} />)
    if (this.state.page === 'complete') return (
      <div className="card" id="applicationWizard">
        <strong>Thank You!</strong>
        <br/>
        Your application has been successfully submitted.
        <br/>
        Someone will be in touch soon for verification and account setup.
        <br/>
        Application Reference Number: {this.state.applicationReferencesNumber}
      </div>
    )
  }

  async submit () {
    try {
      const raw = await fetch('/application', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.state)
      })
      const json = await raw.json()
      if (raw.status !== 200) throw json
      if (json.error) return this.setState({ error: json.error })
      return this.setState({ page: 'complete', applicationReferencesNumber: json.id })
    } catch (e) {
      return this.setState({ error: e.message })
    }
  }

}