document.addEventListener('DOMContentLoaded', () => {

  if (!document.querySelector('#refLookup')) return

  document.querySelector('#refLookup').addEventListener('keyup', e => {
    const value = e.target.value
    e.target.value = value.toUpperCase()
  })

  document.addEventListener('keydown', e => {
    if (e.altKey && e.code === 'KeyL') {
      e.preventDefault()
      document.querySelector('#refLookup').focus()
    }
  })

})