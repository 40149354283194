import React from 'react'

import Goods from './Goods.js'
import Countries from './Countries.js'
import Types from './Types.js'
import Consignments from './Consignments.js'
import CommodityCodes from './CommodityCodes.js'

export default class ImportProfile extends React.Component {

  constructor (props) {
    super(props)
    this.parent = props.parent
    this.state = { errors: {}, fullLoads: false, groupage: false }
    if (this.parent.state.importProfile) this.state = this.parent.state.importProfile
    this.next = this.next.bind(this)
  }

  render () {
    return (
      <div className="card" id="applicationWizard">
        <fieldset>
          <legend>Import Profile</legend>
          <p className="text-center">
            <strong>Please note that currently we cannot assist with the following types of goods:<br/>Live animals, Foodstuffs, Plants, Excise Goods and Controlled (Licensable) Goods</strong>
          </p>
          <div className="form-group">
            <label>Goods</label>
            <Goods parent={this} error={this.state.errors.goods} />
          </div>
          <div className="form-group">
            <label>Countries of Origin</label>
            <Countries parent={this} error={this.state.errors.countries} />
          </div>
          <div className="row">
            <div className="col-5"><Types parent={this} error={this.state.errors.types} /></div>
            <div className="col-4"><Consignments parent={this} error={this.state.errors.consignments} /></div>
            <div className="col-3"><CommodityCodes parent={this} error={this.state.errors.codes} /></div>
          </div>
        </fieldset>
        <div className="row">
          <div className="col-6">
            {
              !this.parent.state.reviewStep
              ? <button type="button" className="btn btn-success float-left" onClick={() => this.parent.setState({page: 'company'})}>Previous</button>
              : ''
            }
          </div>
          <div className="col-6">
            <button type="button" className="btn btn-success float-right" onClick={this.next}>
              {
                this.parent.state.reviewStep
                ? 'Review Application'
                : 'Next'
              }
            </button>
          </div>
        </div>
      </div>
    )
  }

  next () {
    this.setState({ errors: {} })
    const errors = {}
    if (this.state.goods.length === 0) errors.goods = 'Must specify at least one good'
    if (this.state.goods[0] && this.state.goods[0].length === 0) errors.goods = 'Must specify at least one good'
    if (this.state.countries.length === 0) errors.countries = 'Must specify at lease one origin country'
    if (this.state.countries[0] && this.state.countries[0] === '') errors.countries = 'Must specify at least one country of origin'
    if (!this.state.fullLoads && !this.state.groupage) errors.types = 'Must specify one at least one clearance type'
    if (this.state.consignments === 0) errors.consignments = 'Number of monthly consignments cannot be empty or 0'
    if (this.state.codes === 0) errors.codes = 'Number of commodity codes cannot be empty or 0'
    if (Object.keys(errors).length > 0) return this.setState({ errors })
    this.parent.setState({ importProfile: this.state })
    if (this.parent.state.reviewStep) return this.parent.setState({ page: 'review' })
    return this.parent.setState({ page: 'contact' })
  }

}