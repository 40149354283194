import React from 'react'

export default class Goods extends React.Component {

  constructor (props) {
    super(props)
    this.props = props
    this.parent = props.parent
    this.state = { goods: [''] }
    if (this.parent.state.goods && this.parent.state.goods.length > 0) this.state.goods = this.parent.state.goods
    this.renderItem = this.renderItem.bind(this)
    this.updateGoods = this.updateGoods.bind(this)
    this.remove = this.remove.bind(this)
    this.add = this.add.bind(this)
  }

  render () {
    return this.state.goods.map((item, k) => this.renderItem(item, k))
  }

  renderItem (item, k) {
    return (
      <div className="input-group" key={k}>
        <input type="text" className={this.props.error ? 'form-control is-invalid' : 'form-control'} data-key={k} onChange={this.updateGoods} value={item} />
        <div className="input-group-append">
          <button className="btn btn-outline-success" type="button" data-key={k} onClick={this.add}><i className="fas fa-plus"></i></button>
          { this.state.goods.length > 1 ? <button className="btn btn-outline-danger" type="button" data-key={k} onClick={this.remove}><i className="fas fa-trash"></i></button> : '' }
        </div>
        { this.props.error ? <div className="invalid-feedback">{this.props.error}</div> : '' }
      </div>
    )
  }

  add (e) {
    const goods = this.state.goods
    const key = parseInt(e.currentTarget.attributes['data-key'].value) + 1
    goods.splice(key, 0, '')
    this.setState({ goods })
  }

  remove (e) {
    const goods = this.state.goods
    const key = parseInt(e.currentTarget.attributes['data-key'].value)
    goods.splice(key, 1)
    this.setState({ goods })
    return this.parent.setState({ goods })
  }

  updateGoods (e) {
    const value = e.target.value
    const first = value.substr(0,1).toUpperCase()
    e.target.value = `${first}${value.substr(1)}`

    const key = parseInt(e.target.attributes['data-key'].value)
    const goods = this.state.goods
    goods[key] = e.target.value
    this.setState({ goods })
    return this.parent.setState({ goods })
  }

}