import React from 'react'

import Number from './Number'
import Details from './Details'

export default class CompanyInformation extends React.Component {

  constructor (props) {
    super(props)
    this.props = props
    this.parent = props.parent
    this.state = ( this.parent.state.company ? this.parent.state.company : {} )
    this.isNextDisabled = this.isNextDisabled.bind(this)
    this.next = this.next.bind(this)
  }

  render () {
    return (
      <div className="card" id="applicationWizard">
        <fieldset>
          <legend>Company Information</legend>
          <Number parent={this} />
          <Details parent={this} />
          <div className="row">
            <div className="col-12">
              <button type="button" className="btn btn-success float-right" disabled={this.isNextDisabled()} onClick={this.next}>
                {
                  this.parent.state.reviewStep
                  ? 'Review Application'
                  : 'Next'
                }
              </button>
            </div>
          </div>
        </fieldset>
      </div>
    )
  }

  isNextDisabled () {
    if (!this.state.valid) return true
    if (!this.state.vat || !this.state.vat.valid) return true
    if (!this.state.eori || !this.state.eori.valid) return true
    return false
  }

  next () {
    this.parent.setState({ company: this.state })
    if (this.parent.state.reviewStep) return this.parent.setState({ page: 'review' })
    return this.parent.setState({ page: 'import' })
  }

}