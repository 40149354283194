import React from 'react'

export default class CommodityCodes extends React.Component {

  constructor (props) {
    super(props)
    this.props = props
    this.parent = props.parent
    this.state = {}
    this.update = this.update.bind(this)
  }

  render () {
    return (
      <div className="form-group">
        <label>Commodity Codes</label>
        <input type="number" className={this.props.error ? 'form-control is-invalid' : 'form-control'} min={1} onChange={this.update} defaultValue={this.parent.state.codes} />
        { this.props.error ? <div className="invalid-feedback">{this.props.error}</div> : '' }
      </div>
    )
  }

  update (e) {
    return this.parent.setState({codes: parseInt(e.target.value)})
  }

}