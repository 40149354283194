document.addEventListener('DOMContentLoaded', () => {

  if (!document.querySelector('#loginForm')) return

  document.querySelector('#loginForm').querySelector('input[name=reference]').focus()

  document.querySelector('#loginForm').querySelector('input[name=reference]').addEventListener('keyup', e => {
    const value = e.target.value.toUpperCase()
    e.target.value = value
  })

})