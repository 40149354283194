import React from 'react'

export default class Number extends React.Component {

  constructor (props) {
    super(props)
    this.parent = props.parent
    this.state = {
      error: null,
      valid: this.parent.state.valid,
      number: ( this.parent.state.number ? this.parent.state.number : '' )
    }
    this.update = this.update.bind(this)
    this.lookup = this.lookup.bind(this)
    this.companiesHouseDecline = this.companiesHouseDecline.bind(this)
    this.renderAppend = this.renderAppend.bind(this)
    this.inputClassName = this.inputClassName.bind(this)
  }

  render () {
    return (
      <div className="form-group">
        <label>Company Number</label>
        <div className="input-group">
          <input type="text" className={this.inputClassName()} onChange={this.update} onKeyPress={this.lookup} defaultValue={this.state.number} />
          {this.renderAppend()}
          { this.state.error ? <div className="invalid-feedback">{this.state.error}</div> : ''}
          { this.state.valid ? <div className="valid-feedback">Successfully looked up your company information</div> : ''}
        </div>
      </div>
    )
  }

  renderAppend () {
    if (this.state.valid) return null
    return (
      <div className="input-group-append">
        <button className="btn btn-outline-primary" type="button" disabled={ !this.state.number || this.state.loading ? true : false } onClick={this.lookup}>
          { this.state.loading ? <i className="fas fa-spinner fa-spin"></i> : 'Lookup' }
        </button>
      </div>
    )
  }

  inputClassName () {
    let className = 'form-control'
    if (this.state.error) return `${className} is-invalid`
    if (this.state.valid) return `${className} is-valid`
    return className
  }

  update (e) {
    e.target.value = e.target.value.replace(/[^0-9\.]+|\s/g, '')
    return this.setState({ number: e.target.value, valid: false })
  }

  async lookup (e) {
    if (e.type && e.type === 'keypress' && e.charCode !== 13) return
    this.setState({ loading: true, error: null })
    try {
      const raw = await fetch(`/application/lookup/${this.state.number}`)
      const json = await raw.json()
      this.setState({ loading: false })
      if (json.error) throw json.error
      if (json.company_status !== 'active') return this.companiesHouseDecline('INACTIVE COMPANY')
      if (json.has_charges) return this.companiesHouseDecline('HAS CHARGES')
      if (json.has_insolvency_history) return this.companiesHouseDecline('INSOLVENCY HISTORY')
      if (json.registered_office_is_in_dispute) return this.companiesHouseDecline('REGISTERED OFFICE ADDRESS IN DISPUTE')
      if (json.undeliverable_registered_office_address) return this.companiesHouseDecline('UNDELIVERALE REGISTERED OFFICE ADDRESS')
      this.setState({ valid: true })
      return this.parent.setState({
        number: json.company_number,
        registeredOfficeAddress: json.registered_office_address,
        name: json.company_name,
        valid: true
      })
    } catch (e) {
      return this.setState({ loading: false, error: e.message })
    }
  }

  companiesHouseDecline (error) {
    return this.setState({ error: <>Checks from Companies House have failed.  You cannot continue your application.<br/>If you believe this to be in error, please get in contact.</> })
  }

}