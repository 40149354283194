import React from 'react'

export default class Review extends React.Component {

  constructor (props) {
    super(props)
    this.props = props
    this.parent = props.parent
    this.state = { countries: {} }
    this.amend = this.amend.bind(this)
    this.next = this.next.bind(this)
    this.renderClearanceTypes = this.renderClearanceTypes.bind(this)
  }

  async componentDidMount () {
    const raw = await fetch('/application/countries')
    const json = await raw.json()
    this.setState({ countries: json })
  }

  render () {
    return (
      <div id="applicationWizard">
        <div className="card review">
          <fieldset>
            <div className="row">
              <div className="col-8">
                <legend>Company Information</legend>
              </div>
              <div className="col-4">
                <button type="button" className="btn btn-warning float-right" data-page="company" onClick={this.amend}>Amend</button>
              </div>
            </div>
            <table className="table review">
              <tbody>
                <tr>
                  <th>Name</th>
                  <td>{this.parent.state.company.name}</td>
                </tr>
                <tr>
                  <th>Number</th>
                  <td>{this.parent.state.company.number}</td>
                </tr>
                <tr>
                  <th>Address</th>
                  <td>
                    { this.parent.state.company.registeredOfficeAddress.address_line_1 ? this.parent.state.company.registeredOfficeAddress.address_line_1 : '' }
                    { this.parent.state.company.registeredOfficeAddress.address_line_2 ? <><br/>{this.parent.state.company.registeredOfficeAddress.address_line_2}</> : '' }
                    { this.parent.state.company.registeredOfficeAddress.locality ? <><br/>{this.parent.state.company.registeredOfficeAddress.locality}</> : '' }
                    { this.parent.state.company.registeredOfficeAddress.country ? <><br/>{this.parent.state.company.registeredOfficeAddress.country}</> : '' }
                    { this.parent.state.company.registeredOfficeAddress.postal_code ? <><br/>{this.parent.state.company.registeredOfficeAddress.postal_code}</> : '' }
                  </td>
                </tr>
                <tr>
                  <th>VAT Number</th>
                  <td>{this.parent.state.company.vat.number}</td>
                </tr>
                <tr>
                  <th>EORI Number</th>
                  <td>{this.parent.state.company.eori.number}</td>
                </tr>
              </tbody>
            </table>
          </fieldset>
        </div>
        <div className="card review">
          <fieldset>
            <div className="row">
              <div className="col-8">
                <legend>Import Profile</legend>
              </div>
              <div className="col-4">
                <button type="button" className="btn btn-warning float-right" data-page="import" onClick={this.amend}>Amend</button>
              </div>
            </div>
            <table className="table review">
              <tbody>
                <tr>
                  <th>Goods</th>
                  <td>
                    {this.parent.state.importProfile.goods.join(', ')}
                  </td>
                </tr>
                <tr>
                  <th>Origin Countries</th>
                  <td>
                    {this.parent.state.importProfile.countries.map((code) => this.state.countries[code]).join(', ')}
                  </td>
                </tr>
                <tr>
                  <th>Clearance Types</th>
                  <td>{this.renderClearanceTypes()}</td>
                </tr>
                <tr>
                  <th>Monthly Consignments</th>
                  <td>{this.parent.state.importProfile.consignments}</td>
                </tr>
                <tr>
                  <th>Commodity Codes</th>
                  <td>{this.parent.state.importProfile.codes}</td>
                </tr>
              </tbody>
            </table>
          </fieldset>
        </div>
        <div className="card review">
          <fieldset>
            <div className="row">
              <div className="col-8">
                <legend>Contact Information</legend>
              </div>
              <div className="col-4">
                <button type="button" className="btn btn-warning float-right" data-page="contact" onClick={this.amend}>Amend</button>
              </div>
            </div>
            <table className="table review">
              <tbody>
                <tr>
                  <th>Name</th>
                  <td>{this.parent.state.contact.name}</td>
                </tr>
                <tr>
                  <th>Position</th>
                  <td>{this.parent.state.contact.position}</td>
                </tr>
                <tr>
                  <th>Email Address</th>
                  <td>{this.parent.state.contact.email}</td>
                </tr>
                <tr>
                  <th>Number</th>
                  <td>{this.parent.state.contact.number}</td>
                </tr>
              </tbody>
            </table>
          </fieldset>
        </div>
        <div className="row">
          <div className="col-12">
            <button type="button" className="btn btn-success float-right" onClick={this.next}>Submit Application</button>
          </div>
        </div>
      </div>
    )
  }

  renderClearanceTypes () {
    if (this.parent.state.importProfile.fullLoads && this.parent.state.importProfile.groupage) return 'Full Loads & Groupage'
    if (this.parent.state.importProfile.fullLoads && !this.parent.state.importProfile.groupage) return 'Full Loads Only'
    if (!this.parent.state.importProfile.fullLoads && this.parent.state.importProfile.groupage) return 'Groupage Only'
  }

  amend (e) {
    const page = e.currentTarget.attributes['data-page'].value
    return this.parent.setState({ reviewStep: true, page })
  }

  next () {
    return this.parent.submit()
  }

}