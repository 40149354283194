import React from 'react'

import VAT from './VAT'
import EORI from './EORI'

export default class Details extends React.Component {

  constructor (props) {
    super(props)
    this.parent = props.parent
    this.state = {
      name: ( this.parent.state.name ? this.parent.state.name : null ),
      registeredOfficeAddress: ( this.parent.state.registeredOfficeAddress ? this.parent.state.registeredOfficeAddress : null ),
      vat: ( this.parent.state.vat ? this.parent.state.vat : {} ),
      eori: ( this.parent.state.eori ? this.parent.state.eori : {} )
    }
  }

  componentDidUpdate () {
    if (this.parent.state.name !== this.state.name) this.setState({ name: this.parent.state.name })
    if (this.parent.state.registeredOfficeAddress !== this.state.registeredOfficeAddress) this.setState({ registeredOfficeAddress: this.parent.state.registeredOfficeAddress })
    if (this.parent.state.vat !== this.state.vat) this.parent.setState({ vat: this.state.vat })
    if (this.parent.state.eori !== this.state.eori) this.parent.setState({ eori: this.state.eori })
  }

  render () {
    let address = ''
    if (this.state.registeredOfficeAddress) {
      if (this.state.registeredOfficeAddress.address_line_1) address += `${this.state.registeredOfficeAddress.address_line_1}\n`
      if (this.state.registeredOfficeAddress.address_line_2) address += `${this.state.registeredOfficeAddress.address_line_2}\n`
      if (this.state.registeredOfficeAddress.locality) address += `${this.state.registeredOfficeAddress.locality}\n`
      if (this.state.registeredOfficeAddress.country) address += `${this.state.registeredOfficeAddress.country}\n`
      if (this.state.registeredOfficeAddress.postal_code) address += this.state.registeredOfficeAddress.postal_code
    }
    return (
      <>
        <div className="form-group">
          <label>Name</label>
          <input type="text" className="form-control" defaultValue={this.state.name} disabled={true} />
        </div>
        <div className="form-group">
          <label>Address</label>
          <textarea className="form-control" rows="5" defaultValue={address} disabled={true} />
        </div>
        <div className="row">
          <div className="col-6">
            <VAT parent={this} />
          </div>
          <div className="col-6">
            <EORI parent={this} />
          </div>
        </div>
      </>
    )
  }

}
