import React from 'react'
import { render } from 'react-dom'
import jquery from 'jquery'
import bootstrap from 'bootstrap'

import './less/index.less'

import './forgotPassword.js'
import './loginForm.js'
import './refNumber.js'
import './resetPassword.js'
import './search.js'
import './contact.js'

import ApplicationForm from './Components/ApplicationForm/ApplicationForm.js'

document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('ApplicationForm')) render(<ApplicationForm />, document.querySelector('ApplicationForm'))
})