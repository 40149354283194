import React from 'react'

export default class Types extends React.Component {

  constructor (props) {
    super(props)
    this.props = props
    this.parent = props.parent
    this.state = {}
    this.updateCheck = this.updateCheck.bind(this)
  }

  render () {
    return (
      <div className="form-group">
        <label>Clearance Type</label>
        <div className="row">
          <div className="col-12">
            <div className={this.props.error ? 'form-control is-invalid' : 'form-control'}>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="checkbox" data-type="fullLoads" id="fullLoads" onChange={this.updateCheck} defaultChecked={this.parent.state.fullLoads} />
                <label className="form-check-label" htmlFor="fullLoads">
                  Full Loads
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="checkbox" data-type="groupage" id="groupage" onChange={this.updateCheck} defaultChecked={this.parent.state.groupage} />
                <label className="form-check-label" htmlFor="groupage">
                  Groupage
                </label>
              </div>
            </div>
            { this.props.error ? <div className="invalid-feedback">{this.props.error}</div> : '' }
          </div>
        </div>
      </div>
    )
  }

  updateCheck (e) {
    const state = {}
    const type = e.target.attributes['data-type'].value
    state[type] = e.target.checked
    return this.parent.setState(state)
  }

}