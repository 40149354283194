import React from 'react'

export default class ContactInformation extends React.Component {

  constructor (props) {
    super(props)
    this.props = props
    this.parent = props.parent
    this.state = { errors: {} }
    if (this.parent.state.contact) this.state = this.parent.state.contact
    this.update = this.update.bind(this)
    this.next = this.next.bind(this)
  }

  render () {
    return (
      <div className="card" id="applicationWizard">
        <fieldset>
          <legend>Contact Information</legend>
          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <label>Name</label>
                <input type="text" className={this.state.errors.name ? 'form-control is-invalid' : 'form-control'} data-name="name" onChange={this.update} defaultValue={this.state.name} />
                { this.state.errors.name ? <div className="invalid-feedback">{this.state.errors.name}</div> : ''}
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <label>Position</label>
                <input type="text" className={this.state.errors.position ? 'form-control is-invalid' : 'form-control'} data-name="position" onChange={this.update} defaultValue={this.state.position} />
                { this.state.errors.position ? <div className="invalid-feedback">{this.state.errors.position}</div> : '' }
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <label>Email Address</label>
                <input type="text" className={this.state.errors.email ? 'form-control is-invalid' : 'form-control'} data-name="email" onChange={this.update} defaultValue={this.state.email} />
                { this.state.errors.email ? <div className="invalid-feedback">{this.state.errors.email}</div> : '' }
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <label>Contact Number</label>
                <input type="tel" className={this.state.errors.number ? 'form-control is-invalid' : 'form-control'} data-name="number" onChange={this.update} defaultValue={this.state.number} />
                { this.state.errors.number ? <div className="invalid-feedback">{this.state.errors.number}</div> : '' }
              </div>
            </div>
          </div>
        </fieldset>
        <div className="row">
          <div className="col-6">
            {
              !this.parent.state.reviewStep
              ? <button type="button" className="btn btn-success float-left" onClick={() => this.parent.setState({ page: 'import' })}>Previous</button>
              : ''
            }
          </div>
          <div className="col-6">
            <button type="button" className="btn btn-success float-right" onClick={this.next}>
              {
                this.parent.state.reviewStep
                ? 'Review Application'
                : 'Next'
              }
            </button>
          </div>
        </div>
      </div>
    )
  }

  update (e) {
    const name = e.target.attributes['data-name'].value
    const state = {}
    state[name] = e.target.value
    return this.setState(state)
  }

  next () {
    this.setState({errors: {}})
    const errors = {}
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (this.state.email && !re.test(String(this.state.email).toLowerCase())) errors.email = 'Invalid email address'
    if (!this.state.name) errors.name = 'Must enter a contact name'
    if (!this.state.position) errors.position = 'Must enter a contact position'
    if (!this.state.email) errors.email = 'Must enter a contact email address'
    if (!this.state.number) errors.number = 'Must enter a contact number'
    if (Object.keys(errors).length > 0) return this.setState({ errors })
    return this.parent.setState({ contact: this.state, page: 'review' })
  }
 
}