document.addEventListener('DOMContentLoaded', () => {

  if (!document.querySelector('#passwordReset')) return

  document.querySelector('#passwordReset').querySelector('input[name=password]').focus()

  document.querySelector('#passwordReset').addEventListener('submit', e => {
    const passwordElement = e.target.querySelector('input[name=password]')
    const confirmElement = e.target.querySelector('input[name=confirm]')
    if ( passwordElement.classList.contains('is-invalid') ) {
      passwordElement.classList.remove('is-invalid')
      confirmElement.classList.remove('is-invalid')
    }
    const password = passwordElement.value
    const confirm = confirmElement.value
    if ( password === confirm ) return
    if ( password.length >= 8 ) return
    e.preventDefault()
    passwordElement.classList.add('is-invalid')
    confirmElement.classList.add('is-invalid')
  })

})